import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TimeAgo from "react-timeago";
import { Card } from "react-bootstrap";
import { FaEye, FaCheckCircle, FaClock } from "react-icons/fa";

const ContactUs = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState([]);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const viewContact = (row) => {
    setViewData(row);
    setViewModal(true);
  };

  const updateQuery = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/contact-us/update/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        getContactUsData();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
    },
    {
      name: "Created at",
      selector: (row) => <TimeAgo date={row.created_at} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Button
            title="View Query"
            className="m-1"
            variant="success"
            size="sm"
            onClick={() => viewContact(row)}
          >
            <FaEye color="white" />
          </Button>
          {(row.status === null ||
            row.status === 0 ? (
              <Button
                title="Click to complete"
                className="m-1"
                variant="warning"
                size="sm"
                onClick={() => updateQuery(row.id)}
              >
                <FaClock color="white" />
              </Button>
            ) : (
              <Button
                title="Completed"
                className="m-1"
                variant="success"
                size="sm"
              >
                <FaCheckCircle color="white" />
              </Button>
            ))}
        </>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [records, setRecords] = useState(data);

  function handlefilter(event) {
    const searchQuery = event.target.value.toLowerCase();

    const newData = data.filter((row) => {
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const value = row[key];
          const valueString =
            typeof value === "string" || typeof value === "number"
              ? value.toString().toLowerCase()
              : "";
          if (valueString.includes(searchQuery)) {
            return true;
          }
        }
      }
      return false;
    });

    setRecords(newData);
  }

  const getContactUsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/contact-us-data`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      setData(response.data.data);
      setRecords(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {
    getContactUsData();
  }, []);
  const styles = {
    border: "1px solid #2222225c",
  };
  return (
    <Dashboardlayout>
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <h4>Contact us</h4>
              </Card.Header>
              <Card.Body>
                <div className="dataTable" style={{ margin: "13px 0px" }}>
                  <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      onChange={handlefilter}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={records}
                    // selectableRows
                    pagination
                    highlightOnHover
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* View Contact data modal */}
        <Modal
          size="lg"
          show={viewModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">View Contact us</h3>
            <Form.Group>
              <label className="font-weight-bold">Name</label>
              <h5 className="mt-1">{viewData.name}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Email</label>
              <h5 className="mt-1">{viewData.email}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Mobile</label>
              <h5 className="mt-1">{viewData.mobile}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Question</label>
              <h5 className="mt-1">{viewData.question}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Comment</label>
              <h5 className="mt-1">{viewData.comment}</h5>
            </Form.Group>
            <hr />
            <div className="zip-submit-btn d-flex justify-content-end">
              <span
                className="btn btn-danger"
                onClick={() => setViewModal(false)}
              >
                Close
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </Dashboardlayout>
  );
};

export default ContactUs;
