import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { TbBuildingEstate } from "react-icons/tb";
import { FaRegUser, FaQuestionCircle, FaTag,FaGoogle  } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FaHandshake, FaEnvelope, FaComment } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { SiGoogleads } from "react-icons/si";
import whiteLogo from "../../assets/listeligent-logo-white.png";
const Sidebar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };
  return (
    <div className="sidebar-content admin-sidebar">
      <h2 className="p-2 headTitle">
        <Link to={"/"}>
          <img
            src={whiteLogo}
            alt="Listelligent Logo"
            height="30"
            className="d-inline-block align-top"
          />
        </Link>
      </h2>
      <div className="name-circle">
        <span>LL</span>
      </div>
      <hr />
      <ul className="sidebarMenuList">
        <li className={isActive("/admin/dashboard") ? "active" : ""}>
          <AiOutlineDashboard />
          <Link to={"/admin/dashboard"}>DASHBOARD</Link>
        </li>
        <li className={isActive("/admin/orders") ? "active" : ""}>
          <FaShoppingCart />
          <Link to={"/admin/orders"}>Orders</Link>
        </li>
        <li className={isActive("/admin/leads") ? "active" : ""}>
          <FaHandshake />
          <Link to={"/admin/leads"}>Leads</Link>
        </li>
        <li className={isActive("/admin/agentsview") ? "active" : ""}>
          <FaRegUser />
          <Link to={"/admin/agentsview"}>Agents</Link>
        </li>
        <li className={isActive("/admin/zipcode") ? "active" : ""}>
          <FiMapPin />
          <Link to={"/admin/zipcode"}>Zip Code</Link>
        </li>
        <li className={isActive("/admin/state-communities") ? "active" : ""}>
          <TbBuildingEstate />
          <Link to={"/admin/state-communities"}>State Communities</Link>
        </li>
        <li className={isActive("/admin/faqs") ? "active" : ""}>
          <FaQuestionCircle />
          <Link to={"/admin/faqs"}>Faqs</Link>
        </li>
        <li className={isActive("/admin/agent-faqs") ? "active" : ""}>
          <FaQuestionCircle />
          <Link to={"/admin/agent-faqs"}>Agent Faqs</Link>
        </li>
        <li className={isActive("/admin/promo-code") ? "active" : ""}>
          <FaTag />
          <Link to={"/admin/promo-code"}>Promo code</Link>
        </li>
        <li className={isActive("/admin/email-template") ? "active" : ""}>
          <FaEnvelope />
          <Link to={"/admin/email-template"}>Email Template</Link>
        </li>
        <li className={isActive("/admin/contact-us") ? "active" : ""}>
          <FaComment />
          <Link to={"/admin/contact-us"}>Contact Us</Link>
        </li>
       
        <li className={isActive("/admin/pricing") ? "active" : ""}>
          <GiReceiveMoney  />
          <Link to={"/admin/pricing"}>Pricing</Link>
        </li>
      </ul>
      <ul className="sidebarMenuList-mobile">
        <li className={isActive("/admin/dashboard") ? "active" : ""}>
          <Link to={"/admin/dashboard"}>
            <AiOutlineDashboard />
          </Link>
        </li>
        <li className={isActive("/admin/orders") ? "active" : ""}>
          <Link to={"/admin/orders"}>
            <FaShoppingCart />
          </Link>
        </li>
        <li className={isActive("/admin/leads") ? "active" : ""}>
          <Link to={"/admin/leads"}>
            <FaHandshake />
          </Link>
        </li>
        <li className={isActive("/admin/agentsview") ? "active" : ""}>
          <Link to={"/admin/agentsview"}>
            <FaRegUser />
          </Link>
        </li>
        <li className={isActive("/admin/zipcode") ? "active" : ""}>
          <Link to={"/admin/zipcode"}>
            <FiMapPin />
          </Link>
        </li>
        <li className={isActive("/admin/faqs") ? "active" : ""}>
          <Link to={"/admin/faqs"}>
            <FaQuestionCircle />
          </Link>
        </li>
        <li className={isActive("/admin/agent-faqs") ? "active" : ""}>
          <Link to={"/admin/agent-faqs"}>
            <FaQuestionCircle />
          </Link>
        </li>
        <li className={isActive("/admin/promo-code") ? "active" : ""}>
          <Link to={"/admin/promo-code"}>
            <FaTag />
          </Link>
        </li>
        <li className={isActive("/admin/email-template") ? "active" : ""}>
          <Link to={"/admin/email-template"}>
            <FaEnvelope />
          </Link>
        </li>
        <li className={isActive("/admin/contact-us") ? "active" : ""}>
          <Link to={"/admin/contact-us"}>
            <FaComment />
          </Link>
        </li>
       
        <li className={isActive("/admin/pricing") ? "active" : ""}>
        <Link to={"/admin/pricing"}>
        
          <GiReceiveMoney  />
        </Link>
        
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
