import React, { useEffect, useState } from "react";
import Layout from "../../components/Layouts/Layout";
import "../../Style/Agents/Agentsignup.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { AiFillPlayCircle } from "react-icons/ai";
import Stepper from "@mui/material/Stepper";
import InputGroup from "react-bootstrap/InputGroup";
import { BsGeoAltFill } from "react-icons/bs";

import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "react-bootstrap/Accordion";
import agentsignup_page_img from "../../assets/agentsignup_page_img.jpg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { HiMiniCursorArrowRipple } from "react-icons/hi2";
import { CgNotes } from "react-icons/cg";
import { FaHandshake } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaGem } from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";
import orgLogo from "../../assets/listelligent-logo-orange.png";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
const steps = [
  {
    label: "Create a profile",
  },
  {
    label: "Select desired zip codes",
  },
  {
    label: "Start helping sellers",
  },
  // {
  //     label: 'Claim zip codes',
  // }
];

const Agentsignup = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [faqsList, setFaqsList] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);  
  const [modalShowAddress, setModalShowAddress] = useState(false);
    const [address, setAddress] = useState("");
  
   const storedFormDataString = localStorage.getItem("formData");
    const storedFormData = storedFormDataString
      ? JSON.parse(storedFormDataString)
      : null;
    const [cookies, setCookie] = useState(storedFormData);
    const [formData, setFormData] = useState({
      name: cookies ? cookies["name"] : "",
      phone: cookies ? cookies["phone"] : "",
      email: cookies ? cookies["email"] : "",
      address: cookies ? cookies["address"] : "",
      zip_code: cookies ? cookies["zip_code"] : "",
      agent_zip_code: cookies ? cookies["agent_zip_code"] : "",
      state: cookies ? cookies["state"] : "",
    });
  useEffect(() => {
    getFaqs();
  }, []);
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const zipCodeComponent = results[0].address_components.find((component) =>
        component.types.includes("postal_code")
      );

      const newZipCode = zipCodeComponent ? zipCodeComponent.short_name : "";
      const stateComponent = results[0].address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const stateCode = stateComponent ? stateComponent.short_name : "";
      formData.state = stateCode;
      setAddress(selectedAddress);
      formData.address = selectedAddress;
      formData.agent_zip_code = newZipCode;
    } catch (error) {
      console.error("Error fetching location information:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmitzPro = (e) => {
    e.preventDefault();
    addUserLead();
  };
  
  const addUserLead = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/addLead`,
        formData,
        {
          headers: {
            Authorization: `Bearer 455454545ddsdksdjksdjksdsjdksjdksj`,
          },
          withCredentials: true,
        }
      );
      if (response.status) {
        localStorage.setItem("formData", JSON.stringify(formData));
        navigate("/agentslist");
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };
  const getFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent-faqs`,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        setFaqsList(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <Layout>
      <div className="agent-header">
        <Container>
          <Row>
            <Col md={6} className="mb-1">
              <h1>Sell More Homes</h1>
              <p>No Referral Fees</p>
              <p>No Monthly Contracts</p>
              <p>Exclusive Zip Codes</p>
              <div className="text-center text-lg-start" style={{marginTop:"40px"}}>
                <Link
                  to={"/agentsignupform"}
                  className="btn btn-light text-dark newBtn"
                >
                  Join Listelligent&nbsp;
                  <FaArrowRight />
                </Link>
              </div>
              <div className="text-center text-lg-start">
                <Link
                  to={"/login"}
                  className="btn btn-light text-dark mt-1 newBtn"
                >
                  Login&nbsp;
                  <FaArrowRight />
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <img
                alt="agent"
                src={agentsignup_page_img}
                className="img-fluid"
                width={550}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-first-section">
        <Container>
          <Row>
            <Col md={12}>
              <h1>
                Sign up with Listelligent and help homeowners list their home!
              </h1>
            </Col>
          </Row>

          <Row>  
            <Col md={6}>
              <div className="reason-item h-100">

                  <Row className="h-50">
                    <Col md={1}>
                      <CgNotes />
                    </Col>
                    <Col md={11}>
                      <h4>No Contracts</h4>
                        <p>
                          Never get locked into a painful contract ever again!
                          Your subscription will always be month to month for as long or as short as you wish. 
                        </p>
                    </Col>
                  </Row>

                  <Row className="h-50">
                    <Col md={1}>
                      <FaGlobe />
                    </Col>
                    <Col md={11}>
                      <h4>Unlimited Zip Codes</h4>
                      <p>
                        Unlike most platforms that restrict how many zip codes you can buy,
                        Listeligent allows you to purchase as many areas as you wish!
                      </p>
                    </Col>
                  </Row>

              </div>  
            </Col>

            <Col md={6}>
              <div className="reason-item h-100">

                <Row className="h-50">
                  <Col md={1}>
                      <FaHandshake />
                  </Col>
                  <Col md={11}>
                    <h4>No referral fees…ever</h4>
                      <p>
                        Any lead you get from Listelligent is yours for life. If
                        they sell, buy and sell again you keep 100% of the
                        commissions!
                      </p>
                  </Col>
                </Row>

                <Row className="h-50">
                  <Col md={1}>
                      <FaGem />
                  </Col>
                  <Col md={11}>
                    <h4>Exclusive Presence</h4>
                      <p>
                        Be the only expert in your desired zip code. 
                        You will be the only agent featured in the zip code you subscribe to!
                      </p>
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
         
        </Container>
      </div>

      <div className="agentsign-second-section">
        <Container>
          <Row>
            <Col md={6}>
              <h1>How to get started.</h1>
              <Box sx={{ maxWidth: 600 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} className="agent-step-label">
                      <StepLabel
                        className="steplabels"
                        // optional={
                        //   index === 2 ? (
                        //     <Typography variant="caption">Last step</Typography>
                        //   ) : null
                        // }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                        {/* <Box sx={{ mb: 2 }}> */}
                        {/* <div>
                            <Button
                              variant="contained"
                              sx={{ mt: 1, mr: 1 }}
                              className="signup-btn"
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Sign up Now"}                               
                              {index === steps.length - 1 ? (
                                <>Finish</>
                              ) : (
                                // <Link to="/agentsignupform" className="text-white">Sign up Now</Link>
                              )} &nbsp;
                              <FaArrowRight />
                            </Button>
                          </div> */}
                        {/* </Box> */}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Col>
            <Col md={6}>
              <div className="video-popup">
                <img
                  src={require("../../../src/assets/agent-video-thumb.png")}
                  alt="Listelligent Video"
                  className="img-fluid"
                ></img>
                <Link onClick={() => setModalShow(true)}>
                  <AiFillPlayCircle />
                </Link>
                <Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <video
                      alt="Listelligent Video"
                      src={require("../../../src/assets/for-agent.mp4")}
                      width="100%"
                      height="auto"
                      controls
                      autoPlay
                    ></video>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`vs-section`}>
        <div className="container">
          <div className="py-4">
            <h2>A better partner for seller leads.</h2>
          </div>
          <div className="hp-content">
            <div className="left">
              <div className="text-end content-desc">
                <p className="firstContent">
                  <img src={orgLogo} width={200} />
                </p>
                <p>
                  Exclusive Zip Codes{" "}
                  <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  No Contracts <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  No Referral Fees <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                   Sponsored Ads{" "}
                  <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                <p>
                  Free SEO Traffic <FaCheckCircle size={15} color="#ff6c2c" />
                </p>
                
              </div>
            </div>
            <div className="middle">
              <div className="line"></div>
              <div className="vs-circle">VS</div>
            </div>
            <div className="right">
              <div className="text-start content-desc">
                <p className="firstContent">
                  <h4>Other Lead Gen Platforms</h4>
                </p>
                <p></p>
                <p>
                  <IoMdCloseCircle /> Shared Zip Codes
                </p>
                <p>
                  <IoMdCloseCircle /> Annual Contracts With Cancellation
                  Penalties{" "}
                </p>
                <p>
                  <IoMdCloseCircle /> Hefty Referral Fees
                </p>
                <p>
                  <IoMdCloseCircle />  Ads
                </p>
                <p>
                  <IoMdCloseCircle /> No SEO
                </p>
                
              </div>
            </div>
          </div>
          <div className="p-2 getStarted-btn">
            <button
              type="button"
              onClick={() => setModalShowAddress(true)}
              className="find-btn custom-button-css custom-button-orange-bg mt-3"
            >
              GET STARTED
            </button>
          </div>
        </div>
      </div>
      <div className="agentsign-third-section">
        <Container>
          <Row>
            <Col>
              <h1>Frequently Asked Questions</h1>
              <Accordion defaultActiveKey="0" className="accordian-container">
                {faqsList.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header className="acordian-btn">
                      {item.question}
                    </Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="agentsign-fourth-section">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={7}>
              <h1>Ready to take on more listings?</h1>
            </Col>
            <Col md={5}>
              <div className="text-center text-lg-start">
                <Link
                  to={"/agentsignupform"}
                  className="btn btn-light text-dark"
                >
                  Join Listelligent
                  <FaArrowRight />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
            show={modalShowAddress}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="p-3"
          >
            <Modal.Body className="p-5">
              <h5 className="text-left">Search agent</h5>
              <Form onSubmit={handleSubmitzPro}>
                <InputGroup
                  className="mt-3 zip-search-input-content howitwork"
                  style={{
                    width: "100%",
                    border: "1px solid #dee2e6",
                    boxShadow: "none",
                  }}
                >
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="position-relative homeAddress">
                        <input
                          {...getInputProps({
                            placeholder: "Search address",
                            className: "form-control",
                            style: { fontSize: "1.2rem" },
                          })}
                          required
                        />

                        {suggestions.length > 0 && (
                          <div
                            className="position-absolute start-0 mt-2"
                            style={{
                              background: "#fff",
                              border: "1px solid #ced4da",
                              borderRadius: "0.25rem",
                              zIndex: 1000,
                              width: "550px",
                            }}
                          >
                            <ul className="list-group">
                              {loading ? (
                                <li className="list-group-item">Loading...</li>
                              ) : (
                                suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41b6e6"
                                      : "#fff",
                                  };

                                  return (
                                    <li
                                      className="list-group-item"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </li>
                                  );
                                })
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <BsGeoAltFill />
                  <Form.Control
                    type="number"
                    name="agent_zip_code"
                    placeholder="Zip Code"
                    className="shadow-none zip_number"
                    value={formData.agent_zip_code}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>
                <h5 className="text-left mt-4 mb-2">
                  Fill your contact details
                </h5>
                <Form.Control
                  className="mt-3 mb-4"
                  name="name"
                  size="lg"
                  type="text"
                  placeholder="First and Last Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control
                  className="mt-4 mb-4"
                  name="phone"
                  size="lg"
                  type="text"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                {/* <div className='or'><span>OR</span></div> */}
                <Form.Control
                  className="mt-1 mb-4"
                  name="email"
                  size="lg"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {/* <Form.Control
                  className="mt-1 mb-4"
                  name="address"
                  size="lg"
                  type="text"
                  required
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                />
                <Form.Control
                  className="mt-1 mb-4 zip_number"
                  name="zip_code"
                  size="lg"
                  type="number"
                  placeholder="Zip code"
                  value={formData.zip_code}
                  onChange={handleChange}
                  required
                /> */}
                <div className="d-flex justify-content-start">
                  <button
                    type="submit"
                    className="custom-button-css custom-button-orange-bg"
                  >
                    Submit
                  </button>
                  &nbsp;
                  <button
                    variant="danger"
                    className="custom-button-css custom-button-red-bg"
                    onClick={() => setModalShowAddress(false)}
                  >
                    Close
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
    </Layout>
  );
};

export default Agentsignup;
