import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { FaPencilAlt, FaTrash, FaPlus, FaEye } from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import orangeLogo from "../../assets/listelligent-logo-orange.png";

const EmailTemplate = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [templateUpdate, setTemplateUpdate] = useState([]);
  const [data, setData] = useState([]);

  const updateTemplate = (row) => {
    setTemplateUpdate(row);
    setUpdateModal(true);
  };

  const viewTemplate = (row) => {
    setViewData(row);
    setViewModal(true);
  };

  const [values, setValues] = useState({
    email_title: "",
    email_type: "",
    email_content: "",
    header: "",
  });

  const handelInput = (event, name) => {
    const value = event.target ? event.target.value : event; // Check if event has target, if not, it's a direct value

    if (!name) {
      setValues((prev) => ({
        ...prev,
        email_content: value,
      }));
    } else {
      let formattedValue = value;

      if (name === "email_type") {
        formattedValue = value.toLowerCase().replace(/\s+/g, "_");
      }

      setValues((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }
  };

  const handelInputUpdate = (event, name) => {
    const value = event.target ? event.target.value : event; // Check if event has target, if not, it's a direct value

    if (!name) {
      setValues((prev) => ({
        ...prev,
        email_content: value,
      }));
    } else {
      let formattedValue = value;

      if (name === "email_type") {
        formattedValue = value.toLowerCase().replace(/\s+/g, "_");
      }

      setTemplateUpdate((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }
  };

  const handelSubmit = async (event) => {
    event.preventDefault();
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}emailTemplate/add`,
        {
          email_title: values.email_title,
          email_type: values.email_type,
          email_content: values.email_content,
          header: values.header,
          isDefault: 0,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        setModalShow(false);
        setValues({
          email_title: "",
          email_type: "",
          email_content: "",
          header: "",
        });
        NotificationManager.success(
          "Email Template",
          response.data.message,
          1500
        );
        getTemplates();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const handelUpdate = async (event) => {
    event.preventDefault();
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}emailTemplate/edit/${templateUpdate.id}`,
        templateUpdate,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        setUpdateModal(false);
        NotificationManager.success(
          "Email Template",
          response.data.message,
          1500
        );
        getTemplates();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const deleteTemplate = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}emailTemplate/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        getTemplates();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  useEffect(() => {}, [data]);

  const getTemplates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}emailTemplate/list`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        setData(response.data.data);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <Dashboardlayout>
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={11}>
                    <h4>Email Template</h4>
                  </Col>
                  <Col md={1}>
                    <Button
                      title="add Email Template"
                      size="sm"
                      variant="success"
                      onClick={() => setModalShow(true)}
                    >
                      <FaPlus />
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="datatable table-responsive">
                  <Table hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Header</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.email_title}</td>
                          <td>{item.header}</td>
                          <td className="d-inline-flex">
                            <Button
                              title="View Template"
                              className="m-1"
                              variant="success"
                              size="sm"
                              onClick={() => viewTemplate(item)}
                            >
                              <FaEye color="white" />
                            </Button>
                            <Button
                              title="Edit"
                              className="m-1"
                              variant="warning"
                              size="sm"
                              onClick={() => updateTemplate(item)}
                            >
                              <FaPencilAlt color="white" />
                            </Button>
                            {item.isDefault !== 1 && (
                              <Button
                                title="Delete"
                                variant="danger"
                                className="m-1"
                                size="sm"
                                onClick={() => deleteTemplate(item.id)}
                              >
                                <FaTrash />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Add Email Template model */}
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Add Email template</h3>
            <Form onSubmit={handelSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Email title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="email_title"
                  placeholder="Title e.g Registration"
                  className="shadow-none"
                  onChange={(event) => handelInput(event, "email_title")}
                  value={values.email_title}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email type</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Email Type e.g new_account"
                  name="email_type"
                  className="shadow-none"
                  value={values.email_type}
                  onChange={(event) => handelInput(event, "email_type")}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Header</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="e.g Your are Welcome"
                  name="header"
                  value={values.header}
                  className="shadow-none"
                  onChange={(event) => handelInput(event, "header")}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <label className="font-weight-bold" htmlFor="email_content">
                  Body (Content)
                </label>
                <ReactQuill
                  name="email_content"
                  id="email_content"
                  onChange={(value) => handelInput(value, "email_content")}
                  value={values.email_content}
                />
              </Form.Group>

              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end add Email Template modal */}
        {/* update Email Template modal */}
        <Modal
          size="lg"
          show={updateModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Update Email Template</h3>
            <Form onSubmit={handelUpdate}>
              <Form.Group className="mb-3">
                <Form.Label>Email title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="email_title"
                  placeholder="Title e.g Registration"
                  className="shadow-none"
                  onChange={(event) => handelInputUpdate(event, "email_title")}
                  value={templateUpdate.email_title}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email type</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Email Type e.g new_account"
                  name="email_type"
                  disabled={templateUpdate.isDefault}
                  className="shadow-none"
                  onChange={(event) => handelInputUpdate(event, "email_type")}
                  value={templateUpdate.email_type}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Header</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="e.g Your are Welcome"
                  name="header"
                  className="shadow-none"
                  onChange={(event) => handelInputUpdate(event, "header")}
                  value={templateUpdate.header}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <label className="font-weight-bold" htmlFor="email_content">
                  Body (Content)
                </label>
                <ReactQuill
                  name="email_content"
                  id="email_content"
                  onChange={(value) =>
                    handelInputUpdate(value, "email_content")
                  }
                  value={templateUpdate.email_content}
                />
              </Form.Group>

              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Update</Button> &nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setUpdateModal(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* View Email Template modal */}
        <Modal
          size="lg"
          show={viewModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">View Email Template</h3>
            <Form.Group>
              <label className="font-weight-bold">Title</label>
              <h5>{viewData.email_title}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Type</label>
              <h5>{viewData.email_type}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Header</label>
              <h5>{viewData.header}</h5>
            </Form.Group>
            <hr />
            <Form.Group>
              <label className="font-weight-bold">Body (Content)</label>
              <div>
                <table
                  role="presentation"
                  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  width="100%"
                  style={{ margin: "auto" }}
                >
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        backgroundColor: "#ff6c2c",
                        padding: "20px",
                      }}
                    >
                      <h2
                        style={{
                          color: "#ffffff",
                          fontWeight: 700,
                          margin: "0",
                        }}
                      >
                        Listelligent
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "20px" }}>
                      <table
                        role="presentation"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="30%"
                        style={{ float: "left" }}
                      >
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            <img
                              src={orangeLogo}
                              alt="Listelligent Logo"
                              width="150"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          </td>
                        </tr>
                      </table>

                      <table
                        role="presentation"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="70%"
                        style={{ float: "left" }}
                      >
                        <tr>
                          <td
                            style={{
                              color: "#333",
                              fontSize: "14px",
                              lineHeight: "1.6",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: viewData.email_content,
                              }}
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", padding: "20px" }}>
                      <div style={{ fontWeight: 700 }}>Best regards</div>
                      <p>Listelligent Team</p>
                    </td>
                  </tr>
                </table>
              </div>
            </Form.Group>
            <div className="zip-submit-btn d-flex justify-content-end">
              <span
                className="btn btn-danger"
                onClick={() => setViewModal(false)}
              >
                Close
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </Dashboardlayout>
  );
};

export default EmailTemplate;
