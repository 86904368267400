import React from "react";
import "../../Style/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactUsModal from "../ContactUsModal";
import whiteLogo from "../../assets/listeligent-logo-white.png"
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={9}>
            <img src={whiteLogo} alt="Listelligent Logo" height="30" className="mb-2"/>
            {/* <p>{process.env.REACT_APP_ADDRESS_1}</p>
            <p>{process.env.REACT_APP_ADDRESS_2}</p> */}
          </Col>
          <Col md={3}>
            <h4>Company</h4>
            <ul className="footer-link">
              <li><Link to={"/agentsFAQ"}>Help</Link></li>
              <li><Link to={"/term-condition"}>Terms Of Service</Link></li>
              <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
              <li><Link to={"/cookie-policy"}>Cookies</Link></li>
              <li><Link to={"/disclaimer"}>Disclaimer</Link></li>
            </ul>
          </Col>
          <Col md={3}></Col>
          <Col md={3}></Col>
        </Row>
      </Container>
      <ContactUsModal />
    </div>
  );
};

export default Footer;
