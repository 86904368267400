import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import Fab from "@mui/material/Fab";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Typography, IconButton } from "@mui/material";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../Style/custom.css";

const ContactUsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    question: "",
    name: "",
    email: "",
    mobile: "",
    comment: "",
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handelInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const floatButton = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: "10px",
    backgroundColor: "#FF6C2C",
    color: "#fff",
  };
  const handelSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}user/contact-us`,
        values,
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        closeModal();
        setValues({
          question: "",
          name: "",
          email: "",
          mobile: "",
          comment: "",
        });
        NotificationManager.success("Contact", response.data.message, 5000);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  return (
    <div className="contactUsModal">
      <NotificationContainer />
      <Fab
        color="primary"
        onClick={openModal}
        aria-label="add"
        style={floatButton}
      >
        <FaEnvelope size={20} />
      </Fab>
      <Modal
        show={isModalOpen}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          style={{
            backgroundColor: "#FF6C2C",
            padding: "20px",
          }}
        >
          <center>
            <IconButton color="inherit" className="p-4 bg-white">
              <FaEnvelope size={25} />
            </IconButton>
          </center>
          <Typography
            variant="h6"
            className="font-weight-bold text-white mt-1"
            align="center"
            gutterBottom
          >
            Contact Us
          </Typography>
        </div>
        <Modal.Body className="bg-white">
          <Form onSubmit={handelSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                required
                as="select"
                name="question"
                className="shadow-none"
                onChange={handelInput}
                value={values.question || ""}
              >
                <option value="">How can we best serve you?</option>
                <option value="General Inquiry">General Inquiry</option>
                <option value="Support">Support</option>
                <option value="Sales">Sales</option>
                <option value="Billing">Billing</option>
                <option value="Report/Complaints">Report/Complaints</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="Name"
                className="shadow-none"
                onChange={handelInput}
                value={values.name || ""}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Email"
                className="shadow-none"
                onChange={handelInput}
                value={values.email || ""}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                type="text"
                name="mobile"
                placeholder="Mobile"
                className="shadow-none"
                onChange={handelInput}
                value={values.mobile || ""}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                required
                as="textarea"
                name="comment"
                placeholder="Comment or Questions"
                className="shadow-none"
                onChange={handelInput}
                value={values.comment || ""}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="custom-button-css custom-button-orange-bg"
              >
                Send
              </Button>
              &nbsp;
              <Button
                variant="danger"
                className="custom-button-css custom-button-red-bg"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactUsModal;
