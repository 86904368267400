import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";

const CheckoutForm = ({ amount, cartItems, promoCode }) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement || cardElement._empty || cardElement._invalid) {
      NotificationManager.error(
        "Error",
        "Card details are missing or invalid.",
        3000
      );
      return;
    }
    setLoading(true);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      NotificationManager.error("Error", error.message, 4500);
      return false;
    }
    if (paymentMethod) {
      const responseInit = await axios.post(
        `${process.env.REACT_APP_BASE_URL}paymentFrontSide`,
        {
          paymentMethod: paymentMethod.id,
          promoCode: promoCode,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!responseInit.status) {
        NotificationManager.error("Error", responseInit.error, 3000);
      } else {
        const { data } = responseInit;
        const confirmPayment = await stripe?.confirmCardPayment(
          data.clientSecret
        );
        // console.log(confirmPayment);
        if (confirmPayment?.error) {
          paymentDeclined();
        } else {
          confirmPayment.paymentIntent.cart_total = amount;
          confirmPayment.paymentIntent.promoCode = promoCode;

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BASE_URL}agent/cart/placeOrder`,
              confirmPayment,
              {
                headers: { Authorization: `Bearer ${authToken}` },
                withCredentials: true,
              }
            );
            if (response.status) {
              // console.log(response.data);
              const sleep = (ms) =>
                new Promise((resolve) => setTimeout(resolve, ms));
              console.log(response.data.carts.length);

             

            
              NotificationManager.success(
                "Success",
                response.data.message,
                3000
              );
              localStorage.setItem("orderSuccess", response.data.message);
              const userData = JSON.parse(localStorage.getItem("userData"));
              userData.activeZipcode = cartItems.length;
              localStorage.setItem("userData", JSON.stringify(userData));
              navigate("/agent/orders");
              // fetchCartItems();
            } else {
              NotificationManager.error("Error", response.data.message, 3000);
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.clear();
              navigate("/login");
            } else {
              NotificationManager.error(
                "Error",
                error.response.data.message,
                3000
              );
            }
          }
          // NotificationManager.success("Success", "Payment Success", 3000);
        }
      }
    } else {
      paymentDeclined();
    }

    setLoading(false);
  };
 
  
 
 
  const paymentDeclined = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}payment-declined`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status) {
        NotificationManager.error("Error", response.data.message, 3000);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
 
      <NotificationContainer />
      <div className="form-group">
        <label htmlFor="cardElement" className="mt-3 mb-2">
          <b>Card Details</b>
        </label>
        <CardElement
          id="cardElement"
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#32325d",
                fontFamily: "Arial, sans-serif",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
              },
            },
          }}
          className="form-control"
        />
      </div>
      <Button
        type="submit"
        className="find-btn mt-3 w-100 rounded-pill"
        style={{ height: "45px" }}
        disabled={!stripe || loading}
      >
        {loading ? "Processing..." : "Pay"}
      </Button>
    </form>
  );
};

export default CheckoutForm;
