import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import DataTable from "react-data-table-component";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  FaPencilAlt,
  FaTrash,
  FaCloudUploadAlt,
  FaPlus,
  FaBan,
  FaCheck,
} from "react-icons/fa";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import usStates from "../usStates";
import Loader from "../../components/Loader";

const Pricing = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [zipUpdateData, setZipUpdateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    client_id: "",
    client_secret: "",
    redirecturl: "",
    refresh_token: "",
    developer_token: "",
    customer_id: "",
    manager_id: "",

    api_key: "",
  });
  const updateZip = (row) => {
    setZipUpdateData(row);
    setUpdateModal(true);
  };

  const [values, setValues] = useState({
    monthly_price: "",
    percent_list: "",
    percent_ad: "",
    margin: "",
  });

  const handelInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handelInputUpdate = (event) => {
    const { name, value } = event.target;
    setZipUpdateData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/add/pricing`,
        {
          monthly_price: values.monthly_price,
          percent_list: values.percent_list,
          percent_ad: values.percent_ad,
          margin: values.margin,
          formData:formData
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );

      if (response.status) {
        setLoading(false);
        setModalShow(false);
        NotificationManager.success("Pricing", response.data.message, 1500);
        getZipCodeData();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  const handelUpdate = async (event) => {
    setLoading(true);
    event.preventDefault();
    const authToken = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}admin/pricing/${zipUpdateData.id}`,
        zipUpdateData,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setUpdateModal(false);
        NotificationManager.success("Pricing", response.data.message, 1500);
        getZipCodeData();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  // DeleteZip Function Start
  const deleteZip = async (id) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}admin/pricing/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success("Success", response.data.message, 3000);
        getZipCodeData();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  // DeleteZip Function End

  const getStateLabel = (stateValue) => {
    const state = usStates.find((s) => s.value === stateValue);
    return state ? state.label : null;
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Monthly Price ($)",
      selector: (row) => row.monthly_price,
      sortable: true,
    },
    {
      name: "% of listelligent",
      cell: (row) => row.percent_list,
    },
    {
      name: "% of ads",
      selector: (row) => row.percent_ads,
    },
    {
      name: "Margin",
      selector: (row) => row.margin,
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-between">
          <Button
            title="Edit"
            className="m-1"
            variant="warning"
            size="sm"
            onClick={() => updateZip(row)}
          >
            <FaPencilAlt color="white" />
          </Button>
          <Button
            title="Delete"
            variant="danger"
            className="m-1"
            size="sm"
            onClick={() => deleteZip(row.id)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);

  const [records, setRecords] = useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  function handlefilter(event) {
    const searchQuery = event.target.value.toLowerCase();

    const newData = data.filter((row) => {
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          const value = row[key];
          const valueString =
            typeof value === "string" || typeof value === "number"
              ? value.toString().toLowerCase()
              : "";
          if (valueString.includes(searchQuery)) {
            return true;
          }
        }
      }
      return false;
    });

    setRecords(newData);
  }
  const getZipCodeData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/pricing`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setData(response.data.data);
        let newFormData = { ...formData };
        // response.data.google.forEach((item) => {
        //   // console.log(item);
        //   switch (item.name) {
        //     case "client_id":
        //       newFormData["client_id"] = item.value;
        //       break;
        //     case "client_secret":
        //       newFormData["client_secret"] = item.value;
        //       break;
        //     case "developer_token":
        //       newFormData["developer_token"] = item.value;
        //       break;
        //     case "redirecturl":
        //       newFormData["redirecturl"] = item.value;
        //       break;
        //     case "refresh_token":
        //       newFormData["refresh_token"] = item.value;
        //       break;
        //     case "customer_id":
        //       newFormData["customer_id"] = item.value;
        //       break;
        //     case "manager_id":
        //       newFormData["manager_id"] = item.value;
        //       break;
        //     case "api_key":
        //       newFormData["api_key"] = item.value;
        //       break;
        //     default:
        //       break;
        //   }
        // });
        // // getAdslist(newFormData);
        // setFormData(newFormData);
        // console.log(response.data.google);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    getZipCodeData();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setCsvFile(file);

    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("csvFile", file);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}admin/excelZipCode/add`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
        setLoading(false);
        if (response.status) {
          NotificationManager.success("Success", response.data.message, 1500);
          getZipCodeData();
        } else {
          NotificationManager.error("Error", response.message, 3000);
        }
      } catch (error) {
        setLoading(false);
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={10}>
                    <h4>Pricing</h4>
                  </Col>
                  <Col md={2}>
                    <Button
                      title="Add zip code"
                      size="sm"
                      variant="success"
                      onClick={() => setModalShow(true)}
                    >
                      <FaPlus /> Add Price
                    </Button>
                    &nbsp;
                    {/* <Button
                      size="sm"
                      variant="warning"
                      title="Upload csv file to add zip code"
                    >
                      <label htmlFor="uploadFileInput">
                        <FaCloudUploadAlt />
                      </label>
                      <input
                        id="uploadFileInput"
                        type="file"
                        accept=".csv"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Button> */}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <div className="datatable">
                  {/* <div
                    className="dataTableHeader"
                    style={{ margin: "13px 0px" }}
                  >
                    <Form.Control
                      className="shadow-none"
                      type="text"
                      id="inputtext5"
                      placeholder="Search..."
                      onChange={handlefilter}
                    />
                  </div> */}
                  <DataTable
                    columns={columns}
                    data={records}
                    selectableRows
                    pagination
                    highlightOnHover
                    customStyles={{
                      headRow: {
                        style: {
                          fontSize: "18px",
                          fontWeight: "bolder",
                        },
                      },
                      rows: {
                        style: {
                          fontSize: "16px",
                        },
                      },
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Add zip model */}
        <Modal
          show={modalShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Add Price</h3>
            <Form onSubmit={handelSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Monthy price</Form.Label>
                <Form.Control
                  required
                  step="0.01"
                  type="number"
                  name="monthly_price"
                  placeholder="Enter Monthly for Subscription"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>% Split To Listelligent</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="0.01"
                  name="percent_list"
                  placeholder="Enter % Split To Listelligent"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>% Split To Ads</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="percent_ad"
                  step="0.01"
                  placeholder="Enter % Split To Ads"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Margin</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="margin"
                  step="0.01"
                  placeholder="Enter Margin"
                  className="shadow-none"
                  onChange={handelInput}
                />
              </Form.Group>

              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Submit</Button>&nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setModalShow(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* end add zip modal */}
        {/* update zip modal */}
        <Modal
          size="lg"
          show={updateModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <h3 className="zip-header">Update price</h3>
            <Form onSubmit={handelUpdate}>
              <Form.Group className="mb-3">
                <Form.Label>Monthy price</Form.Label>
                <Form.Control
                  required
                  step="0.01"
                  type="number"
                  name="monthly_price"
                  placeholder="Enter Monthly for Subscription"
                  className="shadow-none"
                  onChange={handelInputUpdate}
                  value={zipUpdateData.monthly_price}
                />
              </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label>% Split To listelligent</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="0.01"
                  name="percent_list"
                  placeholder="Enter % Split To Listelligent"
                  className="shadow-none"
                  onChange={handelInputUpdate}
                  value={zipUpdateData.percent_list}
                />
              </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label>% Split To Ads</Form.Label>
                <Form.Control
                  required
                  type="number"
                  step="0.01"
                  name="percent_ads"
               placeholder="Enter % Split To Ads"
                  className="shadow-none"
                  onChange={handelInputUpdate}
                  value={zipUpdateData.percent_ads}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Margin</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="margin"
                  step="0.01"
                  placeholder="Enter Margin"
                  className="shadow-none"
                  onChange={handelInputUpdate}
                  value={zipUpdateData.margin}
                />
              </Form.Group>
             

              <div className="zip-submit-btn d-flex justify-content-end">
                <Button type="submit">Update</Button> &nbsp;
                <span
                  className="btn btn-danger"
                  onClick={() => setUpdateModal(false)}
                >
                  Close
                </span>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </Dashboardlayout>
  );
};

export default Pricing;
