import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader";

const CheckoutForm = ({ amount,cartItems, promoCode,product }) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  // console.log(product);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(PaymentElement);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      NotificationManager.error("Error", submitError.message, 3000);
      // console.error(submitError);
      return;
    }
    if (!cardElement) {
      NotificationManager.error("Error", "Card details are missing.", 3000);
      return;
    }

    setLoading(true);
    const responseInit = await axios.post(
      `${process.env.REACT_APP_BASE_URL}paymentBuyClicks`,
      {
        promoCode: promoCode,
        amount: amount,
        clicks:cartItems,
        zipcode:product.zip_code,
        order_id:product.order_id
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(responseInit);
    // const result = await responseInit.json();
    const clientSecret = responseInit.data.clientSecret;
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://example.com/order/123/complete", // Adjust this URL as needed
      },
      redirect: "if_required", // Use "if_required" to handle redirects for 3D Secure
    });
    console.log(error);

    if (error) {
      NotificationManager.error("Payment error", 3000);
    }
    else{
      NotificationManager.success("Success", "Payment successful!", 3000);
         navigate("/leads");
    }
    // Create a PaymentMethod
    // const { paymentMethod, error } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    // });

    // if (error) {
    //   NotificationManager.error("Error", error.message, 4500);
    //   setLoading(false);
    //   return;
    // }

    // if (paymentMethod) {
    //   try {
    //     // Send payment method and amount to your server
    //     const responseInit = await axios.post(
    //       `${process.env.REACT_APP_BASE_URL}paymentBuyClicks`,
    //       {
    //         paymentMethod: paymentMethod.id,
    //         promoCode: promoCode,
    //         amount: amount,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (responseInit.status === 200) {
    //       const { clientSecret } = responseInit.data;
    //       console.log(responseInit);
    //       // Confirm the PaymentIntent
    //       const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
    //         elements,
    //         clientSecret,
    //         confirmParams: {
    //           return_url: "https://example.com/order/123/complete", // Customize as needed
    //         },
    //         redirect: "if_required",
    //       });
    //       console.log(confirmError);
    //       console.log(paymentIntent);

    //       if (confirmError) {
    //         console.log(confirmError);
    //         paymentDeclined();
    //         NotificationManager.error("Error", confirmError.message, 4500);
    //       } else if (paymentIntent && paymentIntent.status === "succeeded") {
    //         // Handle successful payment
    //         NotificationManager.success("Success", "Payment successful!", 3000);
    //         navigate("/success"); // Redirect as needed
    //       }
    //     } else {
    //       // console.log(responseInit);

    //       NotificationManager.error("Error", responseInit.data.error, 3000);
    //     }
    //   } catch (error) {
    //     console.log(paymentMethod);

    //     NotificationManager.error("Error", "An unexpected error occurred.", 3000);
    //   }
    // } else {
    //   paymentDeclined();
    // }

    setLoading(false);
  };

  const paymentDeclined = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}payment-declined`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        NotificationManager.error("Error", response.data.message, 3000);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", "An unexpected error occurred.", 3000);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && <Loader />}
      <NotificationContainer />
      <div className="form-group">
        <label htmlFor="paymentElement" className="mt-3 mb-2">
          <b>Payment Details</b>
        </label>
        <PaymentElement id="paymentElement" className="form-control" />
      </div>
      <Button
        type="submit"
        className="find-btn mt-3 w-100 rounded-pill"
        style={{ height: "45px" }}
        disabled={!stripe || loading}
      >
        {loading ? "Processing..." : "Pay"}
      </Button>
    </form>
  );
};

export default CheckoutForm;
