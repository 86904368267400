import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm_clicks";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY_USA);


const StripePayment = ({ total, cartItems, promoCode,product }) => {
  const [customAmount, setCustomAmount] = useState(total);

  const handleAmountChange = (event) => {
    setCustomAmount(event.target.value);
  };
  useEffect(() => {
    setCustomAmount(total);
  }, [total]);
  const options = {
    mode: "payment",
    amount: customAmount * 100,
    currency: "usd",
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="customAmount">
          <b>Order Amount</b>
        </label>
        <input
          id="customAmount"
          type="number"
          value={customAmount}
          disabled
          className="form-control"
        />
      </div>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          amount={customAmount}
          product={product}
          cartItems={cartItems}
          promoCode={promoCode}
        />
      </Elements>
    </div>
  );
};

export default StripePayment;
