import React, { useEffect, useState } from "react";
import Dashboardlayout from "../../components/Admin/Dashboardlayout";
import "../../Style/Admin/zipcode.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { CiDiscount1 } from "react-icons/ci";
import { FiDownload } from "react-icons/fi";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Card } from "react-bootstrap";
import "../../Style/custom.css";
import TimeAgo from "react-timeago";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "../../components/Loader";

const AgentZipsView = () => {
  const { id } = useParams();
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [data]);

  const getAgentOwnZips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/agent-zips/${id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setData(response.data.data.orderData);
        setUserData(response.data.data.userData);
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  useEffect(() => {
    getAgentOwnZips();
  }, [id]);

  const handleConfirmBox = (id, order_id) => {
    confirmAlert({
      title: <span className="orange-title">Confirm</span>,
      message: "Are you sure you want to cancel product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleSuProductDelete(id, order_id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSuProductDelete = async (id, order_id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/product-cancel/${id}/${order_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success(
          "Subscription",
          response.data.message,
          3000
        );
        getAgentOwnZips();
      } else {
        NotificationManager.error("Error", response.data.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  return (
    <Dashboardlayout>
      {loading && <Loader />}
      <Container fluid>
        <NotificationContainer />
        <Row>
          <Col md={12}>
            <Card className="mt-4 mb-4">
              <Card.Header>
                <Row>
                  <Col md={11}>
                    <h4>Agent-Owned Zip Codes</h4>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Table hover>
                  <tbody>
                    <tr>
                      <th>Agent name</th>
                      <td>{userData?.name}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{userData?.email}</td>
                    </tr>
                  </tbody>
                </Table>
                <h5 className="mt-4">List of Zip Codes</h5>
                <hr />
                <div className="datatable table-responsive">
                  <Table>
                    <thead>
                      <th>City</th>
                      <th>Zip code</th>
                      <th>Price</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Remark</th>
                    </thead>
                    <tbody>
                      {data?.map((order, orderIndex) =>
                        order.orderProduct.map((product, productIndex) => (
                          <tr key={productIndex}>
                            <td>{product.city}</td>
                            <td>{product.zip_code}</td>
                            <td>{product.price}</td>
                            <td>{product.start_date}</td>
                            <td>{product.end_date}</td>
                            <td>
                              {product.status === 1
                                ? "Active"
                                : product.status === 2
                                ? "Cancelled"
                                : ""}
                            </td>
                            <td>
                              {product.status === 2 ||
                              (product.isCancel !== "" &&
                                product.isCancel !== null) ? (
                                <Button
                                  className="p-2 btn-sub find-btn mb-3 custom-button-orange-bg btn-dark"
                                  disabled
                                >
                                  Cancelled
                                </Button>
                              ) : (
                                <Button
                                  className="btn-sub find-btn mb-3 custom-button-orange-bg"
                                  onClick={() =>
                                    handleConfirmBox(product.id, order.id)
                                  }
                                >
                                  Cancel
                                </Button>
                              )}
                            </td>
                            <td>
                              {product.isCancel !== "" &&
                                product.isCancel === 1 && (
                                  <span style={{fontSize:"13px"}}>End at <br/>{product.end_date}</span>
                                )}
                              {product.isCancel !== "" &&
                                product.isCancel === 0 && (
                                  <span style={{fontSize:"13px"}}>
                                    Ended by admin<br/>on {product.cancel_at}
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))
                      )}
                      {data.length === 0 && (
                        <tr>
                          <td colSpan={5}>
                            <br></br>
                            <h5>No zip codes found.</h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Dashboardlayout>
  );
};

export default AgentZipsView;
