import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "../../Style/Auth/login.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isShow, setIsShow] = useState(true);
  const handleInput = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    // axios
    //   .post(`${process.env.REACT_APP_BASE_URL}agent/forgot-password-request`, {
    //     email,
    //   })
    //   .then((res) => {
    //     if (res.status) {
    //       // navigate("/agent/purchase-zip");
    //       setIsShow(false);
    //       //   NotificationManager.success("Error", res.data.message, 3000);
    //     } else {
    //       NotificationManager.error("Error", res.message, 3000);
    //     }
    //   })
    //   .catch((err) =>
    //     NotificationManager.error("Error", err.response.data.message, 3000)
    //   );
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}agent/forgot-password-request`,
          { email },
          {
            withCredentials: true,
          }
        );
        if (response.status) {
          // NotificationManager.success("Signup", response.data.message, 1500);
          setIsShow(false);
        } else {
          NotificationManager.error("Error", response.message, 3000);
        }
      } catch (error) {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
  };

  return (
    <div className="forgot-password-form-container login-form-container">
      <div className="container h-100">
        <NotificationContainer />
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-9">
            <div className="AppForm shadow-lg">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  {isShow ? (
                    <div className="AppFormLeft">
                      <h1>Forgot Password</h1>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group
                          className="position-relative mb-4"
                          controlId="formBasicEmail"
                        >
                          <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={handleInput}
                            className="form-control shadow-none form-input"
                          />
                        </Form.Group>

                        <Button
                          type="submit"
                          className="w-100 submit-btn btn-block shadow border-0 py-2 text-uppercase"
                        >
                          Send
                        </Button>

                        <p className="text-center mt-5">
                          Remember your password? &nbsp;
                          <Link to={"/login"} className="new-account">
                            Login
                          </Link>
                        </p>
                      </Form>
                    </div>
                  ) : (
                    <center>
                      <h4 className="text-success">Email sent successfully.</h4>
                      <Link to={"/login"} className="new-account btn text-white find-btn">
                        Login
                      </Link>
                    </center>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                    <h2 className="position-relative px-4 pb-3 mb-4">
                      Stay With Listelligent
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
