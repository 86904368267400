import React, { useEffect, useState } from "react";
import Agentlayout from "../../components/Agent/Agentlayout";
import "../../Style/Agents/myprofile.css";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { MdCall } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";
import { FiArrowLeft, FiDownload, FiX } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LuBedDouble } from "react-icons/lu";
import { LuBath } from "react-icons/lu";
import { IoHomeOutline } from "react-icons/io5";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import Loader from "../../components/Loader";
import ReviewImg from "../../assets/review_img.png";

function extractVideoId(youtubeUrl) {
  try {
    const url = new URL(youtubeUrl);
    const pathname = url.pathname.slice(1);
    const videoId = url.searchParams.get("v") || pathname;
    return videoId;
  } catch (error) {
    console.error("Invalid YouTube URL:", youtubeUrl);
    return null;
  }
}

function MyVerticallyCenteredModal(props) {
  const videoId = extractVideoId(props.profileData.video_url);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {videoId ? (
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="Welcome to Listelligent"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ) : (
          <h4>No valid YouTube video found...</h4>
        )}
      </Modal.Body>
    </Modal>
  );
}

const Myprofile = () => {
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [userData, setUserData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);
  const [uImages, setUimages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [agentData, setAgentData] = useState({
    aboutus: "",
    images: "",
    video: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    youtube: "",
    website: "",
    profile: "",
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === "images") {
      const selectedFiles = Array.from(e.target.files);
      setUimages((prevFiles) => [...prevFiles, ...selectedFiles]);

      const newImages = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      const uniqueImages = newImages.filter((image) => !images.includes(image));
      setImages((prevImages) => [...prevImages, ...uniqueImages]);
    } else if (name === "video") {
      const videoFile = files[0];
      setSelectedImage(e.target.files[0]);
    }
  };
  const handleFileChangeProfile = (e) => {
    setAgentData({
      ...agentData,
      profile: e.target.files[0],
    });
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    const updatedSelectedFiles = uImages.filter((file, idx) => idx !== index);
    setImages(newImages);
    setUimages(updatedSelectedFiles);
  };

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}user/profile`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      setUserData(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const getAgentProfileData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}agent/get/profile-data`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setProfileData(response.data.data);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
      setProfileData(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };

  const handleUpdateData = async (event) => {
    setLoading(true);
    event.preventDefault();
    // const websiteRegex = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+$/;
    const websiteRegex = profileData?.website?.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (profileData.website !== "" && websiteRegex == null) {
      NotificationManager.error(
        "Error",
        "Please enter a valid website URL",
        3000
      );
      setLoading(false);
      return false;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}agent/profile-data/`,
        {
          "images[]": uImages,
          description: profileData.description,
          facebook: profileData.facebook,
          linkedin: profileData.linkedin,
          twitter: profileData.twitter,
          youtube: profileData.youtube,
          website: profileData.website,
          profile: agentData.profile,
          video: profileData.video_url,
          license: profileData.license,
          language: profileData.language,
          review: profileData.review,
          phone: profileData.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "content-type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        setSelectedImage(null);
        setUimages([]);
        setImages([]);
        handleCloseModal();
        setAgentData({
          aboutus: "",
          images: "",
          video: "",
          facebook: "",
          linkedin: "",
          twitter: "",
          youtube: "",
          profile: "",
        });
        getUserProfile();
        getAgentProfileData();
        NotificationManager.success("Upload", response.data.message, 1500);
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  useEffect(() => {
    getUserProfile();
    getAgentProfileData();
  }, []);

  function isValidYouTubeUrl(url) {
    const regex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|watch\?v=|v\/|ytscreeningroom\?v=|yts\/))|youtu\.be\//;
    return regex.test(url);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      video_url: value,
    }));
  };

  const isFormDisabled = !isValidYouTubeUrl(profileData.video_url);

  const handleDeleteImage = async (id, name) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}agent/delete/promotional/image/${id}`,
        { name },
        {
          headers: { Authorization: `Bearer ${authToken}` },
          withCredentials: true,
        }
      );
      setLoading(false);
      if (response.status) {
        NotificationManager.success("Image", response.data.message, 1500);
        getAgentProfileData();
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      } else {
        NotificationManager.error("Error", error.response.data.message, 3000);
      }
    }
  };
  const handlePhoneCall = () => {
    const telUrl = `tel:${profileData.phone}`;
    window.open(telUrl);
  };
  return (
    <Agentlayout>
      {loading && <Loader />}
      <div className="myprofile-conteiner">
        <Container>
          <NotificationContainer />
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            className="addBalanceModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update your about us data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleUpdateData} encType="multipart/form-data">
                <Form.Group>
                  <Form.Label htmlFor="aboutus">Agent Bio</Form.Label>
                  <ReactQuill
                    name="description"
                    id="aboutus"
                    className="mt-2"
                    value={profileData.description || ""}
                    onChange={(value) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        description: value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="promotionalImages">
                    Featured Content
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="promotionalImages"
                    name="images"
                    onChange={handleFileChange}
                    multiple
                    accept=".jpg, .jpeg, .png, .gif, .svg"
                  />
                </Form.Group>
                {images.length > 0 && (
                  <div className="preview-container mt-2">
                    <Row>
                      {images.map((image, index) => (
                        <Col md={4} key={index}>
                          <div key={index} className="image-preview">
                            <img
                              src={image}
                              alt={`preview-${index}`}
                              width={150}
                              height={100}
                            />
                            <Button
                              variant="danger"
                              className="p-1"
                              size="sm"
                              onClick={() => removeImage(index)}
                            >
                              {/* <FiX/> */}
                              <BsTrash />
                            </Button>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="profile_img">
                    Profile picture (Leave blank if no changes are made.)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="profile"
                    id="profile_img"
                    onChange={handleFileChangeProfile}
                    accept=".jpg, .jpeg, .png, .gif, .svg"
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="language">Language</Form.Label>
                  <Form.Control
                    type="text"
                    name="language"
                    id="language"
                    className="mt-2"
                    value={profileData.language || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        language: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="license">License</Form.Label>
                  <Form.Control
                    type="text"
                    name="license"
                    id="license"
                    className="mt-2"
                    value={profileData.license || userData.license}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        license: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="phone">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    id="phone"
                    className="mt-2"
                    value={profileData.phone || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        phone: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="video_url">Youtube link</Form.Label>
                  <Form.Control
                    type="url"
                    name="video"
                    id="video_url"
                    value={profileData.video_url || ""}
                    // onChange={(e) =>
                    //   setProfileData((prevProfileData) => ({
                    //     ...prevProfileData,
                    //     video_url: e.target.value,
                    //   }))
                    // }
                    onChange={handleInputChange}
                  />
                  {profileData.video_url &&
                    !isValidYouTubeUrl(profileData.video_url) && (
                      <Form.Text className="text-danger">
                        Invalid YouTube URL
                      </Form.Text>
                    )}
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="facebook">
                    Facebook profile url
                  </Form.Label>
                  <Form.Control
                    type="url"
                    name="facebook"
                    id="facebook"
                    className="mt-2"
                    value={profileData.facebook || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        facebook: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="linkedin">
                    Linkedin profile url
                  </Form.Label>
                  <Form.Control
                    type="url"
                    name="linkedin"
                    id="linkedin"
                    className="mt-2"
                    value={profileData.linkedin || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        linkedin: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="twitter">Twitter profile url</Form.Label>
                  <Form.Control
                    type="url"
                    name="twitter"
                    id="twitter"
                    className="mt-2"
                    value={profileData.twitter || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        twitter: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="youtube">Youtube profile url</Form.Label>
                  <Form.Control
                    type="url"
                    name="youtube"
                    id="youtube"
                    className="mt-2"
                    value={profileData.youtube || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        youtube: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="website">Website</Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    id="website"
                    className="mt-2"
                    value={profileData.website || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        website: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-4">
                  <Form.Label htmlFor="review">Review link</Form.Label>
                  <Form.Control
                    type="url"
                    name="review"
                    id="review"
                    className="mt-2"
                    value={profileData.review || ""}
                    onChange={(e) =>
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        review: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
                <div className="d-flex justify-content-start">
                  <Button
                    className="find-btn mt-2 custom-button-css custom-button-orange-bg"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    className="mt-2 custom-button-css custom-button-red-bg"
                    onClick={handleCloseModal}
                  >
                    <FiArrowLeft /> Close
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <Row>
            <Col md={8}>
              <Row className="d-flex align-items-center">
                <Col md={3}>
                  <div className="myprofile-angent-img-icons">
                    {profileData.profile ? (
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${profileData.profile}`}
                      />
                    ) : (
                      <img
                        alt="profile-img"
                        src={require("../../assets/no-image.png")}
                      />
                    )}

                    <div className="d-flex justify-content-center mt-3 myprofile-social-icons">
                      {profileData.review && (
                        <a
                          href={profileData.review}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            title="Customer review"
                            src={ReviewImg}
                            alt="Review"
                            height={30}
                            style={{ width: "30px" }}
                          />
                        </a>
                      )}
                      {profileData.facebook && (
                        <a
                          href={profileData.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFacebook className="facebook" />
                        </a>
                      )}

                      {profileData.linkedin && (
                        <a
                          href={profileData.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaLinkedin className="linkedin" />
                        </a>
                      )}

                      {profileData.twitter && (
                        <a
                          href={profileData.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaTwitter className="twitter" />
                        </a>
                      )}

                      {profileData.youtube && (
                        <a
                          href={profileData.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaYoutube className="youtube" />
                        </a>
                      )}
                      {profileData.website && (
                        <a
                          href={
                            profileData.website?.startsWith("http")
                              ? profileData.website
                              : `http://${profileData.website}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaGlobe />
                        </a>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={9} className="myprofile-content">
                  <h2>{userData.name}</h2>
                  <p>{userData.brokerage}</p>
                  <p>
                    <span>Email</span>
                    {userData.email}
                  </p>
                  {profileData.website !== "" &&
                    profileData.website !== null && (
                      <p>
                        <span>Website</span>
                        <a
                          href={
                            profileData.website?.startsWith("http")
                              ? profileData.website
                              : `http://${profileData.website}`
                          }
                          target="_blank"
                        >
                          {profileData.website}
                        </a>
                      </p>
                    )}
                  <p>
                    <span>Address</span>
                    {userData.office_address}
                  </p>
                  <p>
                    <span>Agent License</span>
                    {userData.license}
                  </p>
                  <p>
                    <span>Speaks</span>
                    {profileData.language}
                  </p>
                  <p>
                    <span>Phone</span>
                    {profileData.phone ? (
                      <>
                        {profileData.phone}{" "}
                        <Button
                          className="find-btn"
                          size="sm"
                          onClick={handlePhoneCall}
                        >
                          <MdCall size={15} />
                        </Button>
                      </>
                    ) : (
                      <span>N/A</span>
                    )}
                  </p>
                  <p>
                    {" "}
                    <Button
                      className="find-btn custom-button-css custom-button-orange-bg"
                      onClick={handleOpenModal}
                    >
                      Update
                    </Button>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div className="myprofile-video-content">
                {/* {profileData.profile ? (
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${profileData.profile}`}
                  />
                ) : (
                  <img
                    alt="profile-img"
                    src={require("../../assets/no-image.png")}
                  />
                )}
                <Link onClick={() => setModalShow(true)}>
                  <FaPlayCircle />
                </Link> */}
                {extractVideoId(profileData.video_url) ? (
                  <>
                    <iframe
                      style={{ borderRadius: "10px" }}
                      width="100%"
                      height="350"
                      src={`https://www.youtube.com/embed/${extractVideoId(
                        profileData.video_url
                      )}`}
                      title="Welcome to Listelligent"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                ) : (
                  <>
                    {profileData.profile ? (
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_BASE_URL}assets/agent_profile/${profileData.profile}`}
                      />
                    ) : (
                      <img
                        alt="profile-img"
                        src={require("../../assets/no-image.png")}
                      />
                    )}
                    <Link onClick={() => setModalShow(true)}>
                      <FaPlayCircle />
                    </Link>
                  </>
                )}
                <MyVerticallyCenteredModal
                  show={modalShow}
                  profileData={profileData}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </Col>
          </Row>

          <div className="myprofile-agent-about-info">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="myprofile-agent-about-info-heading">
                    <h3>More About {userData.name}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profileData.description,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Row className="myprofile-sub-img-content">
            <Col md={12}>
              <h4>Featured Content</h4>
              <Row className="myprofile-sub-img">
                {profileData?.promotionalImgs &&
                profileData.promotionalImgs.length !== 0 ? (
                  profileData.promotionalImgs.map((image, index) => (
                    <Col key={index} md={4} className="p-2 text-center mb-3">
                      <div className="image-container">
                        <img
                          alt=""
                          className="img-fluid"
                          src={`${process.env.REACT_APP_BASE_URL}assets/agent_images/${image.image}`}
                        />
                      </div>
                      <Button
                        variant="danger"
                        className="custom-button-css custom-button-red-bg"
                        size="sm"
                        onClick={() => handleDeleteImage(image.id, image.image)}
                      >
                        Delete
                      </Button>
                    </Col>
                  ))
                ) : (
                  <p>No promotional images available.</p>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="myprofile-slider">
        <Container>
          <Row>
            <Col md={12}>
              <div className="container-fluid">
                <h1>Featured Transactions</h1>
                <OwlCarousel
                  items={3}
                  className="owl-theme"
                  loop
                  nav
                  margin={8}
                  responsive={{
                    0: { items: 1 },
                    768: { items: 2 },
                    1224: { items: 3 },
                  }}
                >
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279583_880486ef-2021-46fb-a595-559c83272bb1.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Sold</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279588_513b5826-e5a9-4ce1-9fd0-0efca3b158b0.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Sold</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279585_4cbaa342-8d16-4eee-bfb7-0db320359147.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Bought</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279581_698da2e1-bfeb-46c2-aac0-f4a099cee868.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Bought</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279583_880486ef-2021-46fb-a595-559c83272bb1.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Sold</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279588_513b5826-e5a9-4ce1-9fd0-0efca3b158b0.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Bought</p>
                  </div>
                  <div className="myprofile-carousel-card">
                    <img
                      alt=""
                      className="img"
                      src={
                        "https://storage.googleapis.com/upnest1/upload/transaction/279585_4cbaa342-8d16-4eee-bfb7-0db320359147.jpg"
                      }
                    />
                    <div className="myprofile-carousel-card-content">
                      <h4>$1,530,000</h4>
                      <div className="mp-sq-icon">
                        <span>
                          <LuBedDouble />5 Beds
                        </span>
                        <span>
                          <LuBath />5 Baths
                        </span>
                        <span>
                          <IoHomeOutline />
                          4356 Sq. Ft
                        </span>
                      </div>
                      <p>1620 Frazier Ave</p>
                      <p>Carlsbad, CA 92008</p>
                    </div>
                    <p className="myprofile-tag">Sold</p>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </Agentlayout>
  );
};

export default Myprofile;
