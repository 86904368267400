import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "../../Style/Auth/login.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");

  const handleInput = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // axios
    //   .post(`${process.env.REACT_APP_BASE_URL}agent/reset-password/${token}`, {
    //     password,
    //   })
    //   .then((res) => {
    //     if (res.status) {
    //     //   NotificationManager.success("Success", res.data.message, 3000);
    //       navigate("/login");
    //     } else {
    //       NotificationManager.error("Error", res.data.message, 3000);
    //     }
    //   })
    //   .catch((err) =>
    //     NotificationManager.error("Error", err.response.data.message, 3000)
    //   );

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}agent/reset-password/${token}`,
        { password },
        {
          withCredentials: true,
        }
      );
      if (response.status) {
        navigate("/login");
      } else {
        NotificationManager.error("Error", response.message, 3000);
      }
    } catch (error) {
      NotificationManager.error("Error", error.response.data.message, 3000);
    }
  };

  return (
    <div className="reset-password-form-container login-form-container">
      <div className="container h-100">
        <NotificationContainer />
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-9">
            <div className="AppForm shadow-lg">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="AppFormLeft">
                    <h1>Reset Password</h1>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="position-relative mb-4"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          placeholder="Enter new password"
                          onChange={handleInput}
                          className="form-control shadow-none form-input"
                        />
                      </Form.Group>

                      <Button
                        type="submit"
                        className="w-100 submit-btn btn-block shadow border-0 py-2 text-uppercase"
                      >
                        Reset Password
                      </Button>

                      <p className="text-center mt-5">
                        Remember your password? &nbsp;
                        <Link to={"/login"} className="new-account">
                          Login
                        </Link>
                      </p>
                    </Form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                    <h2 className="position-relative px-4 pb-3 mb-4">
                      Stay With Listelligent
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
