import React, { useEffect } from "react";
import Layout from "../components/Layouts/Layout";
import Container from "react-bootstrap/Container";

export default function Cookie() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Layout>
      <Container>
        <div
          name="termly-embed"
          data-id="6d1979bc-42be-455c-9d14-ff35efc1afc1"
          data-type="iframe"
        ></div>
      </Container>
    </Layout>
  );
}
